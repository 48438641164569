import React from "react";

const Palestine = () => {
  const language = localStorage.getItem("selected_language");

  return (
    <div className="palestine-badge">
      <span
        className="support-palestine"
        // href="https://www.islamic-relief.org.uk/giving/appeals/palestine/"
        // href="#"
        // target="_blank"
        aria-label="support Palestine"
        // rel="nofollow noopener noreferrer"
        title="Donate to support palestine">
        <div
          className="support-palestine__flag m-1"
          role="img"
          aria-label="Flag of palestine">
          <div className="background">
            <div className="top"></div>
            <div className="middle"></div>
            <div className="triangle"></div>
          </div>
        </div>
        <div className="support-palestine__label m-1">
          {language === "en" ? "Support Palestine" : "دعمًا لفلسطين"}
        </div>
      </span>
    </div>
  );
};

export default Palestine;

"use client";
/* eslint-disable no-undef */
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "@/api/axios";
import { LOGOUT, CHECK_REVIEW_STATUS } from "@/api/apiUrl";
import { useDispatch } from "react-redux";
import { logoutUser } from "@/store/actions/user_actions";
import { setCurrencyValue } from "@/store/actions/action";
import { toast } from "react-toastify";
import {
  reward_offer_route,
  our_strategy_route,
  our_packages_route,
  user_blogs_route,
} from "@/constants/route_constants";
import { CssStyleRTL } from "@/utility/CssStyleRTL";
// import { useRouter } from "next/navigation";
import { useSearchParams } from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";
import Palestine from "./Palestine";

const Login = dynamic(() => import("../Modal/Login"));
const Registration = dynamic(() => import("../Modal/Registration"));
const ForgatePassword = dynamic(() => import("../Modal/ForgatePassword"));
const Congratulation = dynamic(() => import("../Modal/Congratulation"));
const Questioneers = dynamic(() => import("../Modal/Questioneers"));

const NewHeader = () => {
  const loginRef = useRef(null);
  const myAccountRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const searchParam = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showCongrates, setShowCongrates] = useState(false);
  const language = localStorage.getItem("selected_language");
  const [isSwitchLang, setIsSwitchLang] = useState(false);
  const [ReviewStatus, setReviewStatus] = useState("");
  const [successMsg, setSuccessMsg] = useState({
    show: false,
    msg: "",
  });
  const [AuthUser, setAuthUsers] = useState(
    JSON.parse(localStorage.getItem("AuthUser")) || null
  );
  const [scrolling, setScrolling] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (searchParam.get("paymsg")) {
      toast.success(searchParam.get("paymsg"));
    }

    if (searchParam.get("errormsg")) {
      toast.error(searchParam.get("errormsg"));
    }

    if (searchParam.get("login") && loginRef.current) {
      loginRef.current.click();
    }

    if (localStorage.getItem("selected_language")) {
      i18next.changeLanguage(localStorage.getItem("selected_language"));
      document.getElementsByTagName("html")[0].lang = language;
    } else {
      i18next.changeLanguage("ar");
      document.getElementsByTagName("html")[0].lang = "ar";
    }
  }, [loginRef]);

  const success_msg = (msg) => {
    setSuccessMsg({
      show: true,
      msg: msg,
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccessMsg({
        show: false,
        msg: "",
      });
    }, 5000);
    return () => clearTimeout(timer);
  }, [successMsg]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const router = useRouter();

  const SelectLanguage = (e) => {
    setIsSwitchLang(true);
    let l = "";
    if (language == null || language == "ar") {
      l = "en";
    } else if (language == "en") {
      l = "ar";
    }
    localStorage.setItem("selected_language", l);
    document.getElementsByTagName("html")[0].lang = l;
    i18next.changeLanguage(e);
    window.location.reload();
  };

  const showLoginModal = () => {
    hideCongratesPopup();
    document.getElementById("login").click();
  };

  const logout = async () => {
    document.getElementById("dropdown-menuu").style.visibility = "visible";
    try {
      await axios.get(LOGOUT);
      localStorage.removeItem("AuthUser");
      dispatch(logoutUser());
      myAccountRef.current.classList.remove("show");
      setAuthUsers(null);
      // router.push("/");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("selected_currency")) {
      let currencyData = JSON.parse(localStorage.getItem("selected_currency"));
      dispatch(setCurrencyValue(currencyData));
    }
  }, []);

  const showCongratesPopup = () => {
    setShowCongrates(true);
  };

  const hideCongratesPopup = () => {
    setShowCongrates(false);
  };

  const getReviewStatus = async () => {
    if (AuthUser) {
      try {
        const response = await axios.get(CHECK_REVIEW_STATUS, {
          headers: {
            Authorization: "Bearer " + AuthUser.token,
          },
        });
        setReviewStatus(response.data.check);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getReviewStatus();
  }, []);

  const setIslogin = () => {
    document.getElementById("navbar_supported").click();
  };

  const showProfile = () => {
    setIsMenuOpen(!isMenuOpen);
    document.getElementById("navbar_supported").classList.remove("show");
  };

  const setShownMobile = () => {
    setIsMenuOpen(!isMenuOpen);
    document.getElementById("myaccount_supported").classList.remove("show");
  };

  const scrollToContact = (e) => {
    e.preventDefault();
    const contactUsElement = document.getElementById("contact-us");

    if (contactUsElement) {
      contactUsElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLinkClick = () => {
    document.querySelector(".navbar-collapse").classList.remove("show");
  };

  const handleDocumentClick = (event) => {
    const _opened = document
      .querySelector(".navbar-collapse")
      .classList.contains("show");

    const clickover = event.target;
    if (_opened && !clickover.classList.contains("navbar-toggler")) {
      document.querySelector(".navbar-toggler").click();
    } else {
      const profileOpened = document.querySelector("#myaccount_supported");

      if (profileOpened && profileOpened.classList.contains("show")) {
        document.querySelector(".myaccount-toggler.toggler-two").click();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    const scrollThreshold = 100;

    setScrolling(scrollY > scrollThreshold);
    setIsSticky(scrollY > scrollThreshold);
  };

  return (
    <>
      <CssStyleRTL lg={language} />
      <Palestine />
      <header className="main_header_area main_header_3 mt-0 header-ppp">
        <div className={`header_top ${scrolling ? "scrolling" : ""}`}>
          <div className="container">
            <div className="header_top_inner d-sm-flex justify-content-sm-between">
              <div className="left_info">
                {language == "en" ? (
                  <a
                    href="https://wa.me/+966570123789"
                    rel="noopener noreferrer"
                    aria-label="whatsapp"
                    target="_blank">
                    <i className="fa fa fa-whatsapp"></i>+96 65701 23789
                  </a>
                ) : (
                  <a
                    href="https://wa.me/+966570123789"
                    rel="noopener noreferrer"
                    aria-label="whatsapp">
                    <i className="fa fa fa-whatsapp"></i>
                    {"966570123789"}+
                  </a>
                )}
                <a
                  href="mailto:contact@raaddojo.com"
                  rel="noopener noreferrer"
                  aria-label="contact@raaddojo.com">
                  <i className="fa fa-envelope-o"></i>contact@raaddojo.com
                </a>
              </div>
              <ul className="header_social">
                <li>
                  <a
                    href="https://www.instagram.com/raad_dojo"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="instagram">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/raaddojo"
                    target="_blank"
                    aria-label="facebook"
                    rel="noopener noreferrer">
                    <i className="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@raaddojo"
                    target="_blank"
                    aria-label="youtube"
                    rel="noopener noreferrer">
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`header_menu ${isSticky ? "navbar_fixed" : ""}`}>
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              <div className="menuDiv">
                <button
                  className="navbar-toggler toggler-two"
                  id="doit"
                  onClick={setShownMobile}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar_supported"
                  aria-controls="navbar_supported"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              <Link className="navbar-brand" href="/">
                <Image
                  priority
                  height={35}
                  width={200}
                  src="/new_assets/images/logo100b.png"
                  alt="raad-logo"
                  style={{ objectFit: "contain" }}
                />
                <Image
                  priority
                  height={35}
                  width={200}
                  src="/new_assets/images/logo100b.png"
                  alt="raad-logo"
                  style={{ objectFit: "contain" }}
                />
              </Link>
              {AuthUser || AuthUser?.user?.role === "admin" ? (
                <div className="menuDiv">
                  <button
                    className="myaccount-toggler toggler-two"
                    onClick={showProfile}
                    id="profileBtn"
                    type="button"
                    data-toggle="collapse"
                    data-target="#myaccount_supported"
                    aria-controls="myaccount_supported"
                    aria-expanded="false"
                    aria-label="Toggle my account">
                    <i className="fa fa-user-circle"></i>
                  </button>
                </div>
              ) : (
                ""
              )}

              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbar_supported">
                <ul className="navbar-nav">
                  <li onClick={setIslogin}>
                    {" "}
                    <Link
                      className="nav-link"
                      href="/"
                      onClick={handleLinkClick}>
                      {t("home")}
                      <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li onClick={setIslogin}>
                    <Link
                      className="nav-link"
                      href={our_packages_route}
                      onClick={handleLinkClick}>
                      {t("Packages prices")}
                    </Link>
                  </li>
                  <li onClick={setIslogin}>
                    <Link
                      className="nav-link"
                      href={our_strategy_route}
                      onClick={handleLinkClick}>
                      {t("our_strategy")}
                    </Link>
                  </li>
                  <li onClick={setIslogin}>
                    <Link
                      className="nav-link"
                      href={reward_offer_route}
                      onClick={handleLinkClick}>
                      {t("Rewards")}
                    </Link>
                  </li>
                  <li onClick={setIslogin}>
                    <Link
                      className="nav-link"
                      href={user_blogs_route}
                      onClick={handleLinkClick}>
                      {t("Blogs")}
                    </Link>
                  </li>
                  {AuthUser || AuthUser?.user?.role === "admin" ? (
                    <>
                      <li onClick={setIslogin}>
                        <Link
                          className="nav-link"
                          href="#contact-us"
                          style={{ scrollBehavior: "smooth" }}
                          onClick={handleLinkClick}>
                          {t("contact_us")}
                        </Link>
                      </li>
                      <li className="dropdown menu-highlight">
                        <Link
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-toggle="dropdown"
                          aria-expanded="false">
                          <i className="fa fa-user"></i> {t("my_account")}
                        </Link>
                        <ul
                          className="dropdown-menu membership-dropdown"
                          id="dropdown-menuu">
                          <li>
                            <Link className="nav-link" href="/profile">
                              {t("profile")}
                            </Link>
                          </li>
                          {!AuthUser || AuthUser?.user?.role === "user" ? (
                            <li>
                              <Link className="nav-link" href="/package">
                                {t("packages")}
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}
                          {!AuthUser || AuthUser?.user?.role === "user" ? (
                            <li>
                              <Link className="nav-link" href="/online-classes">
                                {t("Online Classes")}
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}

                          {!AuthUser || AuthUser?.user?.role === "coach" ? (
                            <li>
                              <Link
                                className="nav-link"
                                href="/coach-availability">
                                {t("Availabilities")}
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}
                          <li>
                            <Link className="nav-link" href="/chat">
                              {t("Chat")}
                            </Link>
                          </li>
                          {!AuthUser || AuthUser?.user?.role === "user" ? (
                            <>
                              <li>
                                <Link className="nav-link" href="/rewards">
                                  {t("Rewards points")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="nav-link"
                                  href="/reward-offer-level">
                                  {t("Rewards_Offer")}
                                </Link>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                          <li>
                            <Link className="nav-link" href="/refrale-page">
                              {t("Referral")}
                            </Link>
                          </li>
                          {!AuthUser || AuthUser?.user?.role === "user" ? (
                            <li>
                              <Link className="nav-link" href="/quiz">
                                {t("Quiz")}
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}
                          <li>
                            <Link className="nav-link" href="/bank-details">
                              {t("Bank Account")}
                            </Link>
                          </li>
                          {ReviewStatus == true ? (
                            <li>
                              <Link className="nav-link" href="/rating">
                                {t("Rating")}
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}
                          <li className="nav-item">
                            <a
                              href="#"
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={logout}>
                              {t("logout")}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="dropdown d-none d-md-inline">
                        <a
                          href="#"
                          className="nav-link dropdown-toggle"
                          // href="javascript:void(0);"
                          role="button"
                          data-toggle="dropdown"
                          aria-expanded="false">
                          {t("Membership")}
                        </a>
                        <ul className="dropdown-menu " id="show-member">
                          <li>
                            <a
                              href="#"
                              id="login"
                              data-toggle="modal"
                              data-target="#logoin_modal"
                              ref={loginRef}>
                              {t("log_in")}
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#account_modal"
                              id="create_new_account">
                              {t("Create Account")}
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li onClick={setIslogin}>
                        <a
                          onClick={scrollToContact}
                          className="nav-link"
                          href="#contact-us"
                          style={{ scrollBehavior: "smooth" }}>
                          {t("contact_us")}
                        </a>
                      </li>
                      <li onClick={setIslogin} className="d-md-none">
                        <a
                          href="#"
                          className="nav-link"
                          id="login"
                          data-toggle="modal"
                          data-target="#logoin_modal">
                          {t("log_in")}
                        </a>
                      </li>
                      <li onClick={setIslogin} className="d-md-none">
                        {" "}
                        <a
                          href="#"
                          className="nav-link"
                          data-toggle="modal"
                          data-target="#account_modal"
                          id="create_new_account">
                          {t("Create Account")}
                        </a>
                      </li>
                    </>
                  )}
                  <li className="search_dropdown cursor-pointer ">
                    <Link
                      href="#"
                      data-toggle="tooltip"
                      title="تحويل للغة العربية"
                      onClick={() => {
                        let lg = language == "ar" ? "en" : "ar";
                        SelectLanguage(lg);
                      }}>
                      {!isSwitchLang ? (
                        <Image
                          alt="lang"
                          className="d-none d-md-inline"
                          src="/new_assets/images/lang.png"
                          width={28}
                          height={28}
                          style={{
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <span className="fa fa-spinner fa-spin loader"></span>
                      )}
                    </Link>

                    <Link
                      href="#"
                      data-toggle="tooltip"
                      title="تحويل للغة العربية"
                      onClick={() => {
                        let lg = language == "ar" ? "en" : "ar";
                        SelectLanguage(lg);
                      }}
                      className="d-md-none">
                      {language == "en" ? "اللغة العربية" : "English"}
                    </Link>
                  </li>
                </ul>
              </div>

              <div
                className="collapse navbar-collapse justify-content-end"
                id="myaccount_supported"
                ref={myAccountRef}>
                <ul className="navbar-nav">
                  <li>
                    <Link className="nav-link" href="/profile">
                      {t("profile")}
                    </Link>
                  </li>
                  {!AuthUser || AuthUser?.user?.role === "user" ? (
                    <li>
                      <Link className="nav-link" href="/package">
                        {t("packages")}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {!AuthUser || AuthUser?.user?.role === "user" ? (
                    <li>
                      <Link className="nav-link" href="/online-classes">
                        {t("Online Classes")}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {!AuthUser || AuthUser?.user?.role === "coach" ? (
                    <li>
                      <Link className="nav-link" href="/coach-availability">
                        {t("Availabilities")}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <Link className="nav-link" href="/chat">
                      {t("Chat")}
                    </Link>
                  </li>
                  {!AuthUser || AuthUser?.user?.role === "user" ? (
                    <>
                      <li>
                        <Link className="nav-link" href="/rewards">
                          {t("Rewards points")}
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" href="/reward-offer-level">
                          {t("Rewards_Offer")}
                        </Link>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  <li>
                    <Link className="nav-link" href="/refrale-page">
                      {t("Referral")}
                    </Link>
                  </li>
                  {!AuthUser || AuthUser?.user?.role === "user" ? (
                    <li>
                      <Link className="nav-link" href="/quiz">
                        {t("Quiz")}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <Link className="nav-link" href="/bank-details">
                      {t("Bank Account")}
                    </Link>
                  </li>
                  {ReviewStatus === true ? (
                    <li>
                      <Link className="nav-link" href="/rating">
                        {t("Rating")}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="nav-item">
                    <a
                      href="#"
                      className=""
                      style={{ cursor: "pointer" }}
                      onClick={logout}>
                      {t("logout")}
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <Login setAuthUsers={setAuthUsers} />
      <Registration
        success_msg={success_msg}
        showCongratesPopup={showCongratesPopup}
      />
      <ForgatePassword success_msg={success_msg} />
      <Questioneers />
      {showCongrates && (
        <Congratulation
          hideCongratesPopup={hideCongratesPopup}
          showLoginModal={showLoginModal}
        />
      )}
    </>
  );
};

export default NewHeader;

export const CssStyleRTL = ({ lg }) => {
  let cUrl = window.location.pathname;
  let array = cUrl.split("admin");
  if (array.length > 1) {
    return (
      <>
        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
      </>
    );
  } else {
    if (lg == "en") {
      return <></>;
    } else {
      return (
        <>
          <link href="/new_assets/css/bootstrap-rtl.min.css" rel="stylesheet" />
          <link href="/new_assets/css/style-rtl.css?v=1.6" rel="stylesheet" />
        </>
      );
    }
  }
};

/* eslint-disable no-unused-vars */
import {
  setUserData,
  set_All_User_Data,
  set_All_Couch_Data,
  set_All_Admin_Data,
  set_All_package_Data,
  set_All_plan_Data,
  set_All_invoice_Data,
  set_All_Chat_user_Data,
  set_All_RedeemRequest,
  set_All_RedeemOfferRequest,
  SET_CURRENCY_VALUE_OF_USD,
} from "./../../constants/redux_constants";

import axios from "axios";

let AuthUser = JSON.parse(localStorage.getItem("AuthUser"));

export function setalldata(payload) {
  return {
    type: setUserData,
    payload,
  };
}

export function setAllUserData(payload) {
  return {
    type: set_All_User_Data,
    payload,
  };
}

export const getAllUsers = () => {
  AuthUser = JSON.parse(localStorage.getItem("AuthUser"));
  return (dispatch, getState) => {
    let getallusers = `${process.env.NEXT_PUBLIC_API_PATH}users/allUsers`;
    axios
      .get(getallusers, {
        headers: {
          Authorization: "Bearer " + AuthUser.token,
        },
      })
      .then((res) => {
        console.log(res.data.users);
        dispatch(setAllUserData(res.data.users));
      })
      .catch((error) => console.log(error));
  };
};

export function setAllCouchData(payload) {
  return {
    type: set_All_Couch_Data,
    payload,
  };
}

export const getAllCouch = () => {
  AuthUser = JSON.parse(localStorage.getItem("AuthUser"));
  return (dispatch, getState) => {
    let getallusers = `${process.env.NEXT_PUBLIC_API_PATH}users/allCoach`;
    axios
      .get(getallusers, {
        headers: {
          Authorization: "Bearer " + AuthUser.token,
        },
      })
      .then((res) => {
        dispatch(setAllCouchData(res.data.users));
      })
      .catch((error) => console.log(error));
  };
};

export function setAllAdminData(payload) {
  return {
    type: set_All_Admin_Data,
    payload,
  };
}

export const getAllAdmin = () => {
  AuthUser = JSON.parse(localStorage.getItem("AuthUser"));
  return (dispatch, getState) => {
    let getallusers = `${process.env.NEXT_PUBLIC_API_PATH}admin/getAlladmin`;
    axios
      .get(getallusers, {
        headers: {
          Authorization: "Bearer " + AuthUser.token,
        },
      })
      .then((res) => {
        dispatch(setAllAdminData(res.data.users));
      })
      .catch((error) => console.log(error));
  };
};

export function setAllPackageData(payload) {
  return {
    type: set_All_package_Data,
    payload,
  };
}

export const getAllPackage = () => {
  AuthUser = JSON.parse(localStorage.getItem("AuthUser"));
  return (dispatch) => {
    let getallusers = `${process.env.NEXT_PUBLIC_API_PATH}admin/getAllPackages`;
    axios
      .get(getallusers, {
        headers: {
          Authorization: "Bearer " + AuthUser.token,
        },
      })
      .then((res) => {
        dispatch(setAllPackageData(res.data.packages));
      })
      .catch((error) => console.log(error));
  };
};

export function setAllPlanData(payload) {
  return {
    type: set_All_plan_Data,
    payload,
  };
}

export const getAllPlan = () => {
  AuthUser = JSON.parse(localStorage.getItem("AuthUser"));
  return (dispatch) => {
    let getallusers = `${process.env.NEXT_PUBLIC_API_PATH}admin/getAllPlan`;
    axios
      .get(getallusers, {
        headers: {
          Authorization: "Bearer " + AuthUser.token,
        },
      })
      .then((res) => {
        dispatch(setAllPlanData(res.data.Plan));
      })
      .catch((error) => console.log(error));
  };
};

export function setAllInvoiceData(payload) {
  return {
    type: set_All_invoice_Data,
    payload,
  };
}

export const getAllInvoice = () => {
  AuthUser = JSON.parse(localStorage.getItem("AuthUser"));
  return (dispatch) => {
    let getallusers = `${process.env.NEXT_PUBLIC_API_PATH}admin/getAllTransaction`;
    axios
      .get(getallusers, {
        headers: {
          Authorization: "Bearer " + AuthUser.token,
        },
      })
      .then((res) => {
        dispatch(setAllInvoiceData(res.data.Payments));
      })
      .catch((error) => console.log(error));
  };
};

export function setAllChatUser(payload) {
  return {
    type: set_All_Chat_user_Data,
    payload,
  };
}

export const getAllChatUser = () => {
  AuthUser = JSON.parse(localStorage.getItem("AuthUser"));
  let getallchatusers = `${process.env.NEXT_PUBLIC_API_PATH}chat/getAllUsers`;
  return (dispatch) => {
    axios
      .get(getallchatusers, {
        headers: {
          Authorization: "Bearer " + AuthUser.token,
        },
      })
      .then((res) => {
        dispatch(setAllChatUser(res.data));
      })
      .catch((error) => console.log(error));
  };
};

export function setAllRedeemRequest(payload) {
  return {
    type: set_All_RedeemRequest,
    payload,
  };
}

export const getAllRedeemRequest = () => {
  AuthUser = JSON.parse(localStorage.getItem("AuthUser"));
  let getallRedeemRequest = `${process.env.NEXT_PUBLIC_API_PATH}admin/getAllRedeems`;
  return (dispatch) => {
    axios
      .get(getallRedeemRequest, {
        headers: {
          Authorization: "Bearer " + AuthUser.token,
        },
      })
      .then((res) => {
        dispatch(setAllRedeemRequest(res.data));
      })
      .catch((error) => console.log(error));
  };
};

export function setAllRedeemOfferRequest(payload) {
  return {
    type: set_All_RedeemOfferRequest,
    payload,
  };
}

export const getAllRedeemOfferRequest = () => {
  AuthUser = JSON.parse(localStorage.getItem("AuthUser"));
  let getallRedeemOfferRequest = `${process.env.NEXT_PUBLIC_API_PATH}admin/getAllRedeemsOffer`;
  return (dispatch) => {
    axios
      .get(getallRedeemOfferRequest, {
        headers: {
          Authorization: "Bearer " + AuthUser.token,
        },
      })
      .then((res) => {
        dispatch(setAllRedeemOfferRequest(res.data.redeemoffer));
      })
      .catch((error) => console.log(error));
  };
};

export function setAssignOfferRequest(payload) {
  return {
    type: set_All_RedeemOfferRequest,
    payload,
  };
}

export const getAssignOfferRequest = () => {
  AuthUser = JSON.parse(localStorage.getItem("AuthUser"));
  let getallRedeemOfferRequest = `${process.env.NEXT_PUBLIC_API_PATH}admin/getAllOfferAssign`;
  return (dispatch) => {
    axios
      .get(getallRedeemOfferRequest, {
        headers: {
          Authorization: "Bearer " + AuthUser.token,
        },
      })
      .then((res) => {
        dispatch(setAssignOfferRequest(res.data.offerassign));
      })
      .catch((error) => console.log(error));
  };
};

export function setCurrencyValue(val) {
  return {
    type: SET_CURRENCY_VALUE_OF_USD,
    currency: val,
  };
}

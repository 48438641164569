import { setUserData } from "@/constants/redux_constants";

// ================== Actions =============================
export function setUser(payload) {
  return {
    type: setUserData,
    payload,
  };
}

// ======================= functions ==============================

export const setAuthUser = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;
    let newUser = { ...user };
    newUser.isLoggedIn = true;
    newUser.token = data.token;
    newUser.user = data.user;
    dispatch(setUser(newUser));
  };
};

export const logoutUser = () => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;
    let newUser = { ...user };
    newUser.isLoggedIn = false;
    newUser.token = "";
    newUser.user = {};
    dispatch(setUser(newUser));
  };
};
